import { setFrameSource } from "./frameSource";

const approvedTarget = "__approvedTarget__";
const cardPath = "/___indexFile___";

let iframe;
let childIframe;
let formConfig;

const connectForm = (containingElement, config) => {
  iframe = document.createElement("iframe");
  iframe.style.opacity = "0";
  iframe.style.width = "100%";
  iframe.src = setFrameSource(approvedTarget, cardPath);
  containingElement.innerHTML = "";
  containingElement.appendChild(iframe);
  childIframe = iframe.contentWindow;
  formConfig = config;
};

const sendForm = () => {
  childIframe.postMessage(
    {
      type: "card-form-send",
    },
    approvedTarget
  );
};

const composeForm = (formTypeConfig) => {
  childIframe.postMessage(
    {
      type: "card-form-initialise",
      composition: formTypeConfig,
      ...formConfig,
    },
    approvedTarget
  );
};

const resizeFrame = (height) => {
  iframe.style.height = `${height}px`;
  iframe.style.opacity = "1";
};

export { resizeFrame, composeForm, sendForm, connectForm };
