import {
  sendForm as sendFrameForm,
  composeForm,
  connectForm,
  resizeFrame,
} from "./helpers/frameHelper";

const rejectConnectTimeout = 15000;

const createOnSendForm = (resolver) => () => {
  sendFrameForm();
  return new Promise((resolve, reject) => {
    resolver.resolveSend = resolve;
    resolver.rejectSend = reject;
  });
};

/* eslint-disable-next-line complexity */
const createMessageHandler = (resolver) => (event) => {
  if (event.data.type === "card-form-display") {
    resizeFrame(event.data.height);
    resolver.resizeCallback && resolver.resizeCallback(event.data.height);
    return;
  }

  if (event.data.type === "card-form-submit-requested") {
    resolver.onSubmitCallback && resolver.onSubmitCallback();
  }

  if (event.data.type === "card-form-ready") {
    clearTimeout(resolver.resolveTimer);
    resolver.resolveInstance({
      composeForm,
      sendForm: createOnSendForm(resolver),
    });
    return;
  }

  if (event.data.type === "card-form-complete") {
    resolver.resolveSend(event.data);
    return;
  }

  if (event.data.type === "card-form-invalid") {
    resolver.rejectSend(event.data);
  }
};

let onRecieveMessage = () => {};

window.addEventListener(
  "message",
  (event) => {
    onRecieveMessage(event);
  },
  false
);

const connect = (
  containingElement,
  config,
  resizeCallback,
  onSubmitCallback
) => {
  connectForm(containingElement, config);

  const resolver = {
    resizeCallback,
    onSubmitCallback,
  };
  const instancePromise = new Promise((response, reject) => {
    resolver.resolveInstance = response;
    resolver.resolveTimer = setTimeout(reject, rejectConnectTimeout);
  });

  onRecieveMessage = createMessageHandler(resolver);

  return instancePromise;
};

export default connect;
