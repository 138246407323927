/* eslint-disable import/no-unresolved */
import { appendOrigin } from "__originPackage__";

const setFrameSource = (approvedTarget, cardPath) => {
  const source = new URL(approvedTarget);
  source.pathname = source.pathname + cardPath;
  appendOrigin(source);
  return source;
};

export { setFrameSource };
